module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/codebuild/output/src190026515/src/drpoindexter/public-site/src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"David Poindexter","short_name":"DRP","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"29e30debe2933feb2ae3f0dbca6a944e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/codebuild/output/src190026515/src/drpoindexter/public-site/node_modules/gatsby-remark-images","id":"1054e8da-e3c7-567f-93e6-7717fde33cba","name":"gatsby-remark-images","version":"6.24.0","modulePath":"/codebuild/output/src190026515/src/drpoindexter/public-site/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":700},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/codebuild/output/src190026515/src/drpoindexter/public-site/node_modules/gatsby-remark-prismjs","id":"96900835-4b61-53a8-8a41-f46b0b717fb0","name":"gatsby-remark-prismjs","version":"6.24.0","modulePath":"/codebuild/output/src190026515/src/drpoindexter/public-site/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src190026515/src/drpoindexter/public-site","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
